<template>
  <b-container fluid id="app">
    <Topnavbar
      v-show="$store.state.JWT && !exclusionRoutes.includes($route.name)"
    />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    
    <b-row align-h="center">
      <b-col cols="auto" md="auto">
        Copyright&copy; 2021
        <a href="https://www.plainsolutions.co">Plain Solutions, LLC</a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Topnavbar from "@/components/Topnavbar.vue";

export default {
  name: "App",
  components: {
    Topnavbar,
  },
  data() {
    return {
      exclusionRoutes: ["login", "viewArticle"],
    };
  },
};
</script>

<style lang="scss">
@import "./scss/theme.scss"; 

html {
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
