import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios'
import store from './store/index.js'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import 'video.js/dist/video-js.min.css'
import '@videojs/themes/dist/sea/index.css';


Vue.component('v-select', vSelect)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.mixin({
    methods: {
        handleError: function (error, noAutoHide = false, hideAfter = 3000, showOnRoot = true) {
            if (error.response) {

                let response = error.response.data;
                console.log(response)
                if (response.message.type == "string") {
                    console.log(response.message.message)
                    this.showToast(response.message.message, response.error, 'danger', noAutoHide, hideAfter, showOnRoot)
                    console.log(response.error);
                }
                else {
                    for (const [key, value] of Object.entries(response.message.message)) {
                        let message = "";
                        let count = 0;
                        for (const item of value) {
                            if (count != 0) {
                                message += ", ";
                            }
                            message += item.message;
                            count += 1;
                        }
                        console.log(message)
                        console.log(response.error)
                        this.showToast(message, response.error + " for " + key, 'danger', noAutoHide, hideAfter, showOnRoot)
                    }
                }
            }
            else {
                console.log(error.response.data)
                this.showToast("Unknown error. Please try again.", "Error!", 'danger', noAutoHide, hideAfter, showOnRoot)
            }
        },
        showToast: function (content, title, variant, noAutoHide = false, hideAfter = 3000, showOnRoot = true) {
            if (showOnRoot) {
                this.$root.$bvToast.toast(content, {
                    title: title,
                    noAutoHide: noAutoHide,
                    autoHideDelay: hideAfter,
                    variant: variant
                })
            } else {
                this.$bvToast.toast(content, {
                    title: title,
                    noAutoHide: noAutoHide,
                    autoHideDelay: hideAfter,
                    variant: variant
                })
            }
        },
        isEquivalent: function (a, b) {
            // Create arrays of property names
            var aProps = Object.getOwnPropertyNames(a)
            var bProps = Object.getOwnPropertyNames(b)

            // If number of properties is different,
            // objects are not equivalent
            if (aProps.length !== bProps.length) {
                return false
            }

            for (var i = 0; i < aProps.length; i++) {
                var propName = aProps[i]

                // If values of same property are not equal,
                // objects are not equivalent
                if (propName === '__ob__') {
                    continue
                }
                if (a[propName] !== b[propName]) {
                    return false
                }
            }

            // If we made it this far, objects
            // are considered equivalent
            return true
        }
    }
})

new Vue({
    router,
    store,
    data() {
        return {
            interval: null,
        }
    },
    methods: {
        parseJWT(token) {
            var base64Url = token.split('.')[1]
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            return JSON.parse(window.atob(base64))
        },
        refreshToken: function () {
            let localItem = localStorage.getItem('JWT')
            if (localItem != null) {
                let parsed = this.parseJWT(localItem)
                var ts = Math.round((new Date()).getTime() / 1000)

                let parsedRefresh = this.parseJWT(localStorage.getItem('refreshJWT'))
                if (parsedRefresh.exp - ts > 0) {
                    if (parsed.exp - ts < 30 && parsed.exp - ts > 0) {
                        axios.get(store.state.apiURL + '/account/token', {
                            headers: {
                                Authorization: 'Bearer ' + store.state.refreshJWT
                            }
                        })
                            .then((response) => {
                                store.commit('setToken', {
                                    JWT: response.data.jwt
                                })
                            }, () => {
                                store.commit('clearState', {})
                                router.replace('/login')
                            })
                    } else if (parsed.exp - ts < 0) {
                        axios.get(store.state.apiURL + '/account/token', {
                            headers: {
                                Authorization: 'Bearer ' + store.state.refreshJWT
                            }
                        })
                            .then((response) => {
                                store.commit('setToken', {
                                    JWT: response.data.jwt
                                })
                            }, () => {
                                store.commit('clearState', {})
                                router.replace('/login')
                            })
                    }
                } else { // must login again
                    store.commit('clearState', {})
                    router.replace('/login')
                }

                if (parsedRefresh.exp - ts < 120 && parsedRefresh.exp - ts > 0) {
                    axios.get(store.state.apiURL + '/account/refresh-token', {
                        headers: {
                            Authorization: 'Bearer ' + store.state.refreshJWT
                        }
                    }).then((response) => {
                        store.commit('setRefreshToken', {
                            JWT: response.data.jwt
                        })
                    }, (error) => {
                        if (error.response) {
                            store.commit('clearState', {})
                            router.replace('/login')
                        }
                    })
                } else if (parsedRefresh.exp - ts < 0) {
                    store.commit('clearState', {})
                    router.replace('/login')
                }
            }
        },
    },
    beforeMount() {
        this.interval = setInterval(function () {
            this.refreshToken()
        }.bind(this), 10000)
    },
    beforeDestroy: function () {
        clearInterval(this.interval)
    },
    render: h => h(App),
}).$mount('#app')
