import Vue from 'vue'
import Router from 'vue-router'
import store from './store/index.js'
var axios = require('axios');

Vue.use(Router)

var router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/profile',
            name: 'profile',
            component: () => import('./views/Account/Profile.vue'),
            meta: {
                title: 'Profile'
            }
        },
        {
            path: '/customization',
            name: 'customization',
            component: () => import('./views/Style/Style.vue'),
            meta: {
                title: 'Customization'
            }
        },
        {
            path: '/payment',
            name: 'payment',
            component: () => import('./views/Payment/Payment.vue'),
            meta: {
                title: 'Payment'
            }
        },
        {
            path: '/support',
            name: 'support',
            component: () => import('./views/Support/Support.vue'),
            meta: {
                title: 'Support'
            }
        },
        {
            path: '/compliance',
            name: 'compliance',
            component: () => import('./views/Compliance/Compliance.vue'),
            meta: {
                title: 'Compliance'
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('./views/Other/Settings.vue'),
            meta: {
                title: 'Connection'
            }
        },
        {
            path: '/admins',
            component: () => import('./views/Users/Base.vue'),
            children: [
                {
                    path: '',
                    name: 'manageAdmins',
                    component: () => import('./views/Users/Manage.vue'),
                    meta: {
                        title: 'Manage Admins'
                    }
                },
                {
                    path: 'create',
                    name: 'createAdmin',
                    component: () => import('./views/Users/Create.vue'),
                    meta: {
                        title: 'Create Admin'
                    },
                },
            ]
        },
        {
            path: '/links',
            component: () => import('./views/Links/Base.vue'),
            children: [
                {
                    path: '',
                    name: 'manageLinks',
                    component: () => import('./views/Links/Manage.vue'),
                    meta: {
                        title: 'Manage Links'
                    }
                },
                {
                    path: 'create',
                    name: 'createLink',
                    component: () => import('./views/Links/Create.vue'),
                    meta: {
                        title: 'Create Link'
                    },
                },
                {
                    path: ':id/edit',
                    name: 'editLink',
                    component: () => import('./views/Links/Edit.vue'),
                    props: (route) => ({ id: parseInt(route.params.id) }),
                    meta: {
                        title: 'Edit Link'
                    },
                }
            ]
        },
        {
            path: '/media',
            component: () => import('./views/Media/Base.vue'),
            children: [
                {
                    path: '',
                    name: 'manageMedia',
                    component: () => import('./views/Media/Manage.vue'),
                    meta: {
                        title: 'Manage Media'
                    }
                },
                {
                    path: 'upload',
                    name: 'uploadMedia',
                    component: () => import('./views/Media/Upload.vue'),
                    meta: {
                        title: 'Upload Media'
                    }
                },
                {
                    path: ':id',
                    component: () => import('./views/Media/View.vue'),
                    name: 'viewMedia',
                    props: (route) => ({ id: parseInt(route.params.id) }),
                    meta: {
                        title: 'View Media'
                    }
                },
                {
                    path: ':id/edit',
                    component: () => import('./views/Media/Edit.vue'),
                    name: 'editMedia',
                    props: (route) => ({ id: parseInt(route.params.id) }),
                    meta: {
                        title: 'Edit Media'
                    }
                }
            ]
        },
        {
            path: '/articles',
            component: () => import('./views/Articles/Base.vue'),
            children: [
                {
                    path: '',
                    name: 'manageArticles',
                    component: () => import('./views/Articles/Manage.vue'),
                    meta: {
                        title: 'Manage Articles'
                    }
                },
                {
                    path: 'list',
                    name: 'listArticles',
                    component: () => import('./views/Articles/List.vue'),
                    meta: {
                        title: 'List of Articles'
                    }
                },
                {
                    path: 'create',
                    name: 'createArticle',
                    component: () => import('./views/Articles/Create.vue'),
                    meta: {
                        title: 'Create Article'
                    }
                },
                {
                    path: ':id',
                    component: () => import('./views/Articles/View.vue'),
                    name: 'viewArticle',
                    props: (route) => ({ id: route.params.id }),
                    meta: {
                        title: 'View Article'
                    }
                },
                {
                    path: ':id/edit',
                    component: () => import('./views/Articles/Edit.vue'),
                    name: 'editArticle',
                    props: (route) => ({ id: parseInt(route.params.id) }),
                    meta: {
                        title: 'Edit Article'
                    }
                },
                {
                    path: ':id/preview',
                    component: () => import('./views/Articles/Preview.vue'),
                    name: 'previewArticle',
                    props: (route) => ({ id: parseInt(route.params.id) }),
                    meta: {
                        title: 'Preview Article'
                    }
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Account/Login.vue'),
            meta: {
                title: 'Login'
            }
        },
        {
            path: '/documentation',
            name: 'documentation',
            component: () => import('./views/Documentation/Doc.vue'),
            meta: {
                title: 'Documentation'
            }
        },
        {
            path: '/agreement/:id',
            name: 'agreement',
            component: () => import ('./views/Agreement/Agreement.vue'),
            meta: {
                title: 'Agreement'
            }
        },
        {
            path: '/logout',
            name: 'logout'
        },
        {
            path: '*',
            beforeEnter: (_to, _from, next) => {
                if (store.state.JWT != null) {
                    next({ name: 'manageAdmins' })
                } else {
                    next()
                }
            }
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: () => import ('./views/Agreement/PrivacyPolicy.vue'),
            meta: {
                title: 'Privacy Policy'
            }
        },
        {
            path: '/terms',
            name: 'terms',
            component: () => import ('./views/Agreement/TermsOfUse.vue'),
            meta: {
                title: 'Terms of Use'
            }
        },
        {
            path: '/saas',
            name: 'saas',
            component: () => import ('./views/Agreement/SaaS.vue'),
            meta: {
                title: 'SaaS Agreement'
            }
        },
        {
            path: '/reset',
            name: 'reset',
            component: () => import ('./views/Account/ForgotPassword.vue'),
            meta: {
                title: 'Reset Password'
            }
        },
        {
            path: '/reset-token',
            name: 'reset token',
            component: () => import ('./views/Account/ResetToken.vue'),
            meta: {
                title: 'Reset Password'
            }
        }
    ]
})

function parseJWT(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    return JSON.parse(window.atob(base64))
}

async function nextPage(to, _from, next) {
    if (to.path === '/logout') {
        store.commit('clearState', {})

        return next({
            path: '/login'
        })
    }

    const publicPages = ['login', "agreement", "privacy", "terms", "reset", "reset token"]
    const authRequired = !publicPages.includes(to.name)
    //const adminRequired = adminPages.includes(to.name)

    var loggedIn = true

    if (!store.state.JWT) { // check local storage for token
        let localItem = localStorage.getItem('JWT')
        if (localItem != null) {
            // need to check expiration on JWT
            let parsed = parseJWT(localItem)
            let ts = Math.round((new Date()).getTime() / 1000)
            if (parsed.exp >= ts) { // current time is less than time of expiration
                store.commit('setToken', {
                    JWT: localItem
                })
            } else {
                loggedIn = false
            }
        } else {
            loggedIn = false
        }
    }

    if (!store.state.refreshJWT) { // check local storage for refresh token
        let localItem = localStorage.getItem('refreshJWT')
        if (localItem != null) {
            // need to check expiration on JWT
            let parsed = parseJWT(localItem)
            let ts = Math.round((new Date()).getTime() / 1000)
            if (parsed.exp >= ts) { // current time is less than time of expiration
                store.commit('setRefreshToken', {
                    JWT: localItem
                })
            } else {
                store.commit('clearState', {})
                loggedIn = false
            }
        } else {
            store.commit('clearState', {})
            loggedIn = false
        }
    }

    if (!loggedIn && to.name != 'login' && authRequired) {
        return next({ name: 'login' })
    }
    else if (loggedIn && to.name == 'login' && authRequired) {
        return next({ name: 'manageAdmins' })
    }

    if (!loggedIn && store.state.refreshJWT.length !== 0) {
        let response = await axios.get(store.state.apiURL + '/account/token', {
            headers: {
                Authorization: 'Bearer ' + store.state.refreshJWT
            }
        })
        store.commit('setToken', {
            JWT: response.data.jwt
        })

        return next()
    } else {
        if (authRequired && !loggedIn) {
           return next('/login')
        }

        return next()
    }
}

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return nextPage(to, from, next)

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '')

        return tag
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag))

    return nextPage(to, from, next)
})

export default router