<template>
  <header class id="header-main">
    <b-container fluid class="mx-0 px-0">
      <b-navbar
        toggleable="lg"
        type="dark"
        variant="primary"
        class="one-edge-shadow"
      >
        <b-navbar-brand :to="{ name: 'customization' }">
          <img src="@/assets/logo.png" alt="logo" contain height="35px" />
          Plain Wealth
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
           
            <b-nav-item :to="{ name: 'manageAdmins' }">Admins</b-nav-item>
            <b-nav-item :to="{ name: 'settings' }">Connection</b-nav-item>
            <b-nav-item :to="{ name: 'compliance' }">Compliance</b-nav-item>
            <b-nav-item :to="{ name: 'customization' }"
              >Customization</b-nav-item
            >
            <b-nav-item :to="{ name: 'manageLinks' }">Resources</b-nav-item>

          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown text="Documentation" right>
              <b-dropdown-item :to="{ name: 'documentation' }">View</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'privacy' }">Policy Notice</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'terms' }">Terms of Use</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'saas' }">SaaS Agreement</b-dropdown-item>
              <!-- <b-dropdown-item>
                <a href="@/assets/plain_wealth_doc.pdf">Download</a>
                </b-dropdown-item> -->
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right>
              <template v-slot:button-content>
                <em>User</em>
              </template>
              <b-dropdown-item :to="{ name: 'profile' }"
                >Profile</b-dropdown-item
              >
            
              <b-dropdown-item :to="{ name: 'logout' }"
                >Sign Out</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
export default {
  name: "Topnavbar",
  data() {
    return {};
  },
};
/**  <b-dropdown-item :to="{ name: 'support' }"
                >Support</b-dropdown-item
              > */
</script>

<style scoped>
.one-edge-shadow {
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.navbar-dark .navbar-nav .nav-link {
  color: #e5ad45 !important;
}
.navbar-brand {
  color: #e5ad45 !important;
}

#nav a.router-link-exact-active {
  color: #e5ad45;
}
</style>
