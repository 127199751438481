import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function parseJWT(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    return JSON.parse(window.atob(base64))
}

export default new Vuex.Store({
    state: {
        JWT: '',
        refreshJWT: '',
        loggedInBefore: null,
        admin: null,
        id: 0,
        apiURL: process.env.VUE_APP_API_URL,
        stripePubKey: process.env.VUE_APP_STRIPE_API_KEY,
        firmID: 0,
    },
    getters: {
    },
    mutations: {
        clearState(state) {
            localStorage.removeItem('JWT')
            localStorage.removeItem('refreshJWT')
            state.JWT = ''
            state.refreshJWT = ''
            state.admin = null
            state.id = 0
            state.firmID = 0
        },
        setToken(state, payload) {
            console.log("setting token");
            console.log(payload.JWT);
            localStorage.setItem('JWT', payload.JWT)
            state.JWT = payload.JWT
            if (payload.JWT != null) {
                let parsed = parseJWT(state.JWT)
                state.admin = parsed.admin
                state.id = parsed.id
                state.firmID = parsed.firm_id
            } else {
                state.admin = null
                state.id = 0
                state.firmID = 0
            }
        },
        setRefreshToken(state, payload) {
            localStorage.setItem('refreshJWT', payload.JWT)
            state.refreshJWT = payload.JWT
        },
        setLoggedInBefore(state, payload) {
            localStorage.setItem('loggedInBefore', payload.loggedInBefore);
            state.loggedInBefore = payload.loggedInBefore;
        }
    }
})
